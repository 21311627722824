<template>
  <section class="services">
    <div class="container">
      <h2 class="text-center text-uppercase">Сервисы</h2>
      <div class="container py-5">
        <div class="row">
          <div class="col-md-10 col-sm-12">
            <div class="service">
              <div>
                <img src="https://storage.yandexcloud.net/part4images/service/mfucopy.svg">
              </div>
              <div class="pl-3">
                <h3>Покопийная печать</h3>
                <p>
                  Услуга предусматривает заключение договора долгосрочной аренды оргтехники или обслуживание вашей. Вы
                  оплачиваете фиксированную стоимость за фактическое количество отпечатанных листов, при этом вы
                  получаете бесперебойно функционирующий парк печатного оборудования и гарантию оперативного решения
                  проблем за счет нашей компании.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-2 col-sm-12">
            <div class="service">
              <div>
                <img src="https://storage.yandexcloud.net/part4images/service/mfu.svg">
              </div>
              <div class="pl-3">
                <h3>Абонентское обслуживание</h3>
                <p>Услуга предусматривает заключение договора на долгосрочной основе за единицу техники по фиксированной
                  цене. Мы берем на себя ежемесячное обслуживание в рамках регламента, установленного производителем.
                  Вы забываете про внеплановые расходы и внезапные поломки техники.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-sm-12">
            <div class="service">
              <div>
                <img src="https://storage.yandexcloud.net/part4images/service/repair.svg">
              </div>
              <div class="pl-3">
                <h3>Ремонт оргтехники</h3>
                <p>Классический вид обслуживания оргтехники. Мы обслуживаем вашу технику по факту поломки.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-2 col-sm-12">
            <div class="service">
              <div>
                <img src="https://storage.yandexcloud.net/part4images/service/cartridges.svg">
              </div>
              <div class="pl-3">
                <h3>Заправка и продажа картриджей</h3>
                <p>Предоставляем услуги по заправке картриджей (лазерных/струйных), а также реализацию и продажу
                  оригинальных и совместимых картриджей по низким ценам.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Service"
}
</script>

<style scoped lang="sass">
.service
  margin: 1em 0
  display: flex
  padding: 1em
  border-radius: 10px

.dark-theme
  .service
    background-color: #282840
    box-shadow: 0 4px 6px rgba(37, 35, 48, 0.25)

@media all and (max-width: 768px)
  .service
    flex-direction: column
    div
      &:first-child
        text-align: center
</style>
