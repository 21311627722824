<template>
  <section class="contacts">
    <div class="container">
      <h2 class="text-center text-uppercase">Контакты</h2>
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <form class="my-3" @submit.prevent="onSubmit">
            <h3 class="text-center py-2">Оставьте телефон, чтобы мы смогли Вам помочь</h3>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-tel">Номер телефона:</span>
              </div>
              <input type="tel" v-phone v-model="contactPhone" id="contactPhone" class="form-control"
                     placeholder="+7 (999) 999-9999" aria-describedby="basic-tel" required>
            </div>
            <div class="input-group mb-3">
              <textarea id="contactMessage" v-model="contactMessage" rows="5" class="form-control"
                        placeholder="Сообщение"></textarea>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-lg btn-outline-primary">Оставить заявку</button>
            </div>
            <div v-if="successSend">
              <h4 class="text-success text-center my-3">Ваше сообщение успешно отправлено!</h4>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '../plugins/phone-mask'
import axios from 'axios'
// import telegramApi from "../plugins/telegram";
//
// telegramApi.getUserInfo().then(function(user) {
//   if (user.id) {
//     console.log(user.id)
//   } else {
//     // telegramApi.sendCode('79781300403')
//     window.phone_code_hash = '87912';
//     telegramApi.signIn('79781300403', window.phone_code_hash, '000000').then(function() {
//       // Sign in complete
//       delete window.phone_code_hash;
//     }, function(err) {
//       switch (err.type) {
//         case 'PHONE_CODE_INVALID':
//           // alert "Phone code invalid"
//           break;
//         case 'PHONE_NUMBER_UNOCCUPIED':
//           // User not registered, you should use signUp method
//           break;
//       }
//     });
//   }
// });

export default {
  name: "Contacts",
  data: function () {
    return {
      successSend: false,
      contactPhone: null,
      contactMessage: null
    };
  },
  mounted() {
    axios
        .get('https://api.telegram.org/bot1156153972:AAF909WARiE_mt9qVc5ry6BwPAh3lh_hZes/getMe')
        .then(response => {
          (this.info = response);
          console.log(this.info)
        });
  },
  methods: {
    onSubmit() {
      let contactForm = "телефон: " + this.contactPhone + "    сообщение: " + this.contactMessage
      console.log(contactForm)
      // bot.sendMessage(1156153972, )
      // telegramApi.sendMessage(1156153972, contactForm)
      axios.get('https://api.telegram.org/bot1156153972:AAF909WARiE_mt9qVc5ry6BwPAh3lh_hZes/sendMessage?chat_id=-1001202973106&text=' + contactForm)
      this.successSend = true
      this.contactPhone = null
      this.contactMessage = null
      setTimeout(() => {
        this.successSend = false
      }, 3000)
    }
  }
}
</script>

<style scoped lang="sass">

</style>
