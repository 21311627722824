<template>
  <div id="app" class="dark-theme wrapper">
    <header class="header">
      <div class="container-fluid d-flex">
        <a class="logo hover_text" href="/">
          <span>Part4<br><small>Service</small></span>
        </a>
        <div class="nav menu-top">
          <a href="#repair" v-smooth-scroll="{ duration: 1500, offset: -250 }">Ремонт</a>
          <a href="#service" v-smooth-scroll="{ duration: 1500, offset: -100 }">Сервис</a>
          <a href="#contacts" v-smooth-scroll="{ duration: 1500, offset: -100 }">Контакты</a>
        </div>
      </div>
    </header>

    <div class="content">
      <Repair id="repair"/>
      <Service id="service"/>
      <Contacts id="contacts"/>
    </div>

    <footer class="footer mt-auto py-3">
      <p class="text-center">© Part4 | <a href="mailto:info@part4.info" target="_blank">info@part4.info</a></p>
      <a href="#app" v-smooth-scroll="{ duration: 1500, offset: -100 }" id="to_up" class=""><img src="https://storage.yandexcloud.net/part4images/images/to_up.png"
                                           alt="to_up"></a>
    </footer>
  </div>
</template>

<script>
import Repair from './components/Repair.vue'
import Service from './components/Service.vue'
import Contacts from './components/Contacts.vue'

export default {
  name: 'App',
  components: {
    Repair,
    Service,
    Contacts
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      var posTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      var d = document.getElementById("to_up");
      if (posTop > 200) {
        d.className = 'visible'
      } else {
        d.className = ''
      }
    }
  }
}

</script>

<style lang="sass">

.wrapper
  padding-top: 100px

.dark-theme
  background-color: #202136

  .header
    background-color: #282840

    .menu-top
      a
        color: aliceblue

        &:hover
          color: cornflowerblue

  section, footer
    color: aliceblue

.header
  padding: 20px 0
  position: fixed
  top: 0
  width: 100%
  z-index: 1000

  .logo
    color: #F4F5F9
    background-image: url(https://storage.yandexcloud.net/part4images/images/logo_4v2.svg)
    width: 106px
    height: 40px
    display: flex
    justify-content: flex-end
    align-items: flex-end
    background-repeat: no-repeat
    background-position: left top
    background-size: contain
    vertical-align: bottom
    font-size: 1.3em
    line-height: 1em
    font-weight: 700
    font-family: Ubuntu, Roboto, sans-serif
    text-decoration: none

    small
      color: #78A9D8
      font-size: 0.77em
      font-weight: 700

  .menu-top
    flex-grow: 1
    justify-content: space-around

    a
      font-weight: 700
      font-size: 1.6em

section
  min-height: calc(100vh - 200px)

footer
  font-size: 1.1em


#to_up
  display: none
  position: fixed
  right: 15px
  bottom: 15px
  cursor: pointer
  &.visible
    display: block

@media all and (max-width: 768px)
  .wrapper
    padding-top: 140px


</style>
