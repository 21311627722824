<template>
  <section class="repair">
    <div class="container">
      <h2 class="text-center text-uppercase">Сервисное обслуживаение оргтехники</h2>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="offset-lg-7 col-lg-5 offset-md-5 col-md-7 col-sm-12">
          <div class="steps step1">
            <div>
              <h3>1. Онлайн консультация</h3>
              <p>Бесплатная консультация по телефону</p>
            </div>
            <div>
              <img src="https://storage.yandexcloud.net/part4images/service/247livesupport.svg">
              <a href="#contacts" v-smooth-scroll="{ duration: 1500, offset: -100 }"
                 class="btn btn-lg btn-outline-primary float-right mt-4">Оставить зявку</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-12">
          <div class="steps step2 d-flex">
            <div>
              <img src="https://storage.yandexcloud.net/part4images/service/deliverycar.svg">
            </div>
            <div class="pl-2">
              <h3>2. Бесплатный выезд инженера</h3>
              <p>В удобное для Вас время инженер приедет к вам в офис и быстро проведет все необходимые ремонтные работы
                практически любой сложности</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 offset-lg-6 offset-md-4 col-md-8 col-sm-12">
          <div class="steps step3 d-flex">
            <div>
              <img src="https://storage.yandexcloud.net/part4images/service/note.svg">
            </div>
            <div class="pl-2">
              <h3>3. Согласование цены</h3>
              <p>Инженер производит диагностику и согласовывает стоимость ремонта</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="steps step4 d-flex">
            <div>
              <img src="https://storage.yandexcloud.net/part4images/service/repair.svg">
            </div>
            <div class="pl-2">
              <h3>2. Бесплатный выезд инженера</h3>
              <p>В удобное для Вас время инженер приедет к вам в офис и быстро проведет все необходимые ремонтные работы
                практически любой сложности</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Repair"
}
</script>

<style scoped lang="sass">

.steps
  &:after
    content: ''
    background-repeat: no-repeat
    background-size: contain
    position: absolute
    width: 100%
    top: 100%


.step1
  &:after
    background-image: url("https://storage.yandexcloud.net/part4images/service/shape_s_1.svg")
    right: 50%
    height: 100px

.step2
  margin-top: 80px

  &:after
    background-image: url("https://storage.yandexcloud.net/part4images/service/shape_s_2.svg")
    left: 70%
    height: 200px

.step3
  margin-top: 80px
  &:after
    background-image: url("https://storage.yandexcloud.net/part4images/service/shape_s_3.svg")
    right: 30%
    height: 150px

.step4
  margin-top: 40px

.dark-theme
  .step1
    &:after
      background-image: url("https://storage.yandexcloud.net/part4images/service/d_shape_s_1.svg")

  .step2
    &:after
      background-image: url("https://storage.yandexcloud.net/part4images/service/d_shape_s_2.svg")

  .step3
    &:after
      background-image: url("https://storage.yandexcloud.net/part4images/service/d_shape_s_3.svg")

.dark-theme
  .steps
    padding: 1em
    background-color: #282840
    box-shadow: 0 4px 6px rgba(37, 35, 48, 0.25)
    border-radius: 10px

@media all and (max-width: 768px)
  .steps
    flex-direction: column
    &:after
      display: none
    div
      &:first-child
        text-align: center
  .step2, .step3, .step4
    margin-top: 40px

</style>
